import api from '@/axios'

export default {
  findAll () {
    return api().get('contact-us')
  },
  findOne (id) {
    return api().get(`contact-us/${id}`)
  }
}
