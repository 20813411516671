<template>
  <div>
    <b-card>
      <ValidationObserver ref="contactUs" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="contactUs.contact_us.user.name" :name="$t('clients.item')" :label="$t('clients.item')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="contactUs.contact_us.contactTopic.name" :name="$t('contactUsTopics.name')" :label="$t('contactUsTopics.name')" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <input-form v-model="contactUs.contact_us.subject" :name="$t('global.subject')" :label="$t('global.subject')" :disabled="disableStatus" />
          </b-col>
          <b-col md="12">
            <textarea-form v-model="contactUs.contact_us.message_body" :name="$t('contactUs.messageBody')" :label="$t('contactUs.messageBody')" :disabled="disableStatus" />
          </b-col>
        </b-row>
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import contactUsServices from '../services/contactUs'
import ContactUs from '../models/ContactUs'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      contactUs: new ContactUs()
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      contactUsServices.findOne(this.id).then(response => {
        this.contactUs.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
