export default class ContactUs {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.contact_us = {
      subject: '',
      message_body: '',
      user_id: null,
      user: { name: '' },
      contact_us_topic_id: null,
      contactTopic: { name: '' }
    }
  }

  fillData (data) {
    if (data) {
      this.contact_us.subject = data.subject ? data.subject : ''
      this.contact_us.message_body = data.message_body ? data.message_body : ''
      this.contact_us.user_id = data.user_id ? data.user_id : null
      this.contact_us.user.name = data.user.name ? data.user.name : ''
      this.contact_us.contact_us_topic_id = data.contact_us_topic_id ? data.contact_us_topic_id : null
      this.contact_us.contactTopic.name = data.contactTopic.name ? data.contactTopic.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
